import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Icon } from '@iconify/vue'
import vClickOutside from 'click-outside-vue3'
import veProgress from "vue-ellipse-progress"
import VueLazyLoad from 'vue3-lazyload'
import { QuillEditor } from '@vueup/vue-quill'
import posthog from "posthog-js";
import { apiClient } from "@/helpers/api.js"
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

import { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import brokenImage from '@/assets/images/broken-portrait.png'

import App from './App.vue'
import router from './router'

/* Global css code */
import './css/main.css'

import { SentryInit } from '@/services/sentry'

/* Init Vue app */
const app = createApp(App)

const currentURL = window.location.href;

const isLocalOrDev = window.location.hostname === 'localhost' || window.location.hostname.includes('dev')

/* Init Sentry */
!currentURL.includes('localhost') && SentryInit(app)

/* Init Posthog */
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  autocapture: false,
  disable_session_recording: isLocalOrDev ? true : false,
});

/* Init Iconify component */
app.component('Icon', Icon)

app.component('QuillEditor', QuillEditor)

/* Create Vue app */
app.use(router)
app.use(createPinia())
app.use(vClickOutside)
app.use(veProgress)
app.use(VueLazyLoad, {
  lifecycle: {
    error: (el) => {
      const dataImage = el.getAttribute('data-image')
      const imgWithDataId = document.querySelector(`img[data-image="${dataImage}"]`)

      if (imgWithDataId) {
        imgWithDataId.src = brokenImage
      }
    }
  }
})
app.use(ToastPlugin);

app.directive('tooltip', VTooltip)
app.provide("posthog", posthog)

app.mount('#app')
